import { Icon, Dropdown, IconButton, Button, Drawer, Input, AutoComplete, Timeline } from 'rsuite'
import { withRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useSync, useLive, useStorage, useSession } from '../../../utils/Storken'
import { signOutWithUser } from '../../../services/UserActions'
import { Router } from '../../../../server/routes'
import { api } from '../../../services/_http'
import { getBugun, oncekiGunTarihiniHesapla, teslimatTarihiHesapla } from '../../../services/CustomerActions'
import { getCargoDayMessage } from '../../../pages/public/order/InfoMessage'

// eslint-disable-next-line react/prop-types
const Header = ({ router, ...props }) => {
  const [tokenCustomer] = useStorage('tokenCustomer')
  const [onLeft, OnLeft] = useSync('onleft')
  const [user] = useLive('user')
  const openUrl = url => Router.pushRoute(url)

  const HeaderDrop = () => (
    <Dropdown activeKey={router?.asPath} onSelect={e => openUrl(e)} title={user.username}>
      <Dropdown.Item eventKey='/dashboard'>Yönetim Paneli</Dropdown.Item>
      <Dropdown.Item eventKey='/siparisler'>Siparişler</Dropdown.Item>
      <Dropdown.Item eventKey='/yorumlar'>Yorumlar</Dropdown.Item>
      <Dropdown.Item eventKey='/indirimler'>Kuponlar</Dropdown.Item>
      <Dropdown.Item eventKey='/teklifler'>Teklifler</Dropdown.Item>
      <Dropdown.Item eventKey='/urunler'>Ürünler</Dropdown.Item>
      <Dropdown.Item eventKey='/kategoriler'>Kategoriler</Dropdown.Item>
      <Dropdown.Item eventKey='/yukleme'>Kargo Yükle</Dropdown.Item>
      <Dropdown.Item eventKey='/kargotakip'>Kargo Takibi</Dropdown.Item>

      <Dropdown.Item eventKey='/' onSelect={() => signOutWithUser()}>Çıkış Yap</Dropdown.Item>
    </Dropdown>
  )
  return (
    <header className='app-header'>
      <div className='sol_menu_buton item' onClick={() => OnLeft(!onLeft)}>
        <span />
        <span />
        <span />
      </div>
      <div className='nav-brand item'>
        <span>
          {/* eslint-disable-next-line global-require */}
          <Link href='/'>
            <img src='https://svgl.s3.eu-west-3.amazonaws.com/kg-logo.jpg' alt='' />
          </Link>
        </span>
      </div>
      <div className='right-menu item'>

        {
          (Object.keys(tokenCustomer).length > 0 && tokenCustomer.name)
            ? <a href='/profile'>{tokenCustomer?.name.split(' ')[0]}</a>
            : <a href='/customer-login'>Giriş yap</a>
        }> <Icon icon='user-o' />

      </div>
      <div className='desktop-header-list'>
        <Button className='login-customer-button'>

          {
            (Object.keys(tokenCustomer).length > 0 && tokenCustomer.name)
              ? <a href='/profile'>{tokenCustomer.name}</a>
              : <a href='/customer-login'>Giriş yapın </a>
          }

        </Button>
        <span>
          {
            user.username && <HeaderDrop />
          }
        </span>
      </div>

      <div />

    </header>
  )
}

export default withRouter(Header)
